define("discourse/plugins/swapd-auctions/discourse/components/create-auction", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/url", "discourse-common/utils/decorators", "@ember/utils", "@ember/string", "@ember/component", "@ember/application"], function (_exports, _ajax, _ajaxError, _url, _decorators, _utils, _string, _component, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    title: "",
    starting_price: "",
    min_reserve_price: "",
    buy_now_price: "",
    description: "",
    url: "",
    end_date: null,
    category_id: null,
    payment_method: null,
    loading: false,
    setTopicTemplate() {
      const categoryId = this.get("category_id");
      console.log(categoryId);
      if (!categoryId) {
        return;
      }
      const category = this.site.categories.findBy("id", categoryId);
      if (!category || (0, _utils.isBlank)(category.topic_template)) {
        return;
      }
      this.set("description", category.topic_template);
    },
    actions: {
      setEndDate(date) {
        this.set("end_date", date);
        this.set("end_date_unix", date.unix());
      },
      createAuction() {
        if (this.loading) {
          return;
        }
        const error = msg => {
          const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
          dialog.alert({
            message: msg
          });
        };
        this.set("loading", true);
        const attrs = (0, _string.w)("title starting_price min_reserve_price buy_now_price url description end_date_unix category_id payment_method");
        const data = {};
        attrs.forEach((attr, i) => {
          data[attr] = this.get(attr);
        });
        (0, _ajax.ajax)("/auction/create", {
          type: "POST",
          data: {
            data
          }
        }).catch(_ajaxError.popupAjaxError).then(result => {
          if (result.e) {
            error(result.e);
          } else {
            _url.default.routeTo(result.url);
          }
        }).finally(() => {
          this.set("loading", false);
        });
      }
    }
  }, [["method", "setTopicTemplate", [(0, _decorators.observes)("category_id")]]]));
});