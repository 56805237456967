define("discourse/plugins/swapd-auctions/discourse/routes/create-auction", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    beforeModel() {
      if (!this.currentUser) {
        this.transitionTo("login");
      }
    },
    titleToken() {
      return I18n.t("auction.page_title");
    },
    setupController(controller) {
      this._super(...arguments);
    }
  });
});