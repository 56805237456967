define("discourse/plugins/swapd-auctions/discourse/templates/create-auction", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.swapd_auction_enabled}}
    {{#if this.currentUser.auction_is_id_verified}}
      {{#if this.currentUser.can_create_auction}}
        {{create-auction}}
      {{else}}
        <div class="alert alert-error">
          {{{i18n "auction.cant_create_auction"}}}
        </div>
      {{/if}}
    {{else}}
      <div class="alert alert-error">
        {{{i18n "auction.get_verified"}}}
      </div>
    {{/if}}
  {{else}}
    <div class="alert alert-error">
      {{{i18n "auction.plugin_disabled"}}}
    </div>
  {{/if}}
  
  */
  {
    "id": "5NHjjPhZ",
    "block": "[[[41,[30,0,[\"siteSettings\",\"swapd_auction_enabled\"]],[[[41,[30,0,[\"currentUser\",\"auction_is_id_verified\"]],[[[41,[30,0,[\"currentUser\",\"can_create_auction\"]],[[[1,\"      \"],[41,[28,[32,0],[\"create-auction\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"create-auction\"],[[28,[32,1],[\"create-auction\"],null]]]]],[[[1,[52,[30,1,[\"create-auction\"]],[28,[30,1,[\"create-auction\"]],null,null],[28,[32,2],[[30,0],\"create-auction\",\"[\\\"The `create-auction` property path was used in the `discourse/plugins/swapd-auctions/discourse/templates/create-auction.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.create-auction}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n        \"],[2,[28,[37,5],[\"auction.cant_create_auction\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n      \"],[2,[28,[37,5],[\"auction.get_verified\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n    \"],[2,[28,[37,5],[\"auction.plugin_disabled\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"maybeHelpers\"],false,[\"if\",\"create-auction\",\"let\",\"hash\",\"div\",\"i18n\"]]",
    "moduleName": "discourse/plugins/swapd-auctions/discourse/templates/create-auction.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});