define("discourse/plugins/swapd-auctions/discourse/templates/connectors/category-custom-settings/swapd-auction", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.swapd_auction_enabled}}
    <h3>{{i18n "auction.auction"}}</h3>
  
    <div class="field">
      <label class="checkbox-label">
        {{input
          type="checkbox"
          checked=this.category.custom_fields.auction_enabled
        }}
  
        {{i18n "auction.enabled"}}
      </label>
    </div>
  {{/if}}
  
  */
  {
    "id": "3a5iavqj",
    "block": "[[[41,[30,0,[\"siteSettings\",\"swapd_auction_enabled\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"auction.auction\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"auction_enabled\"]]]]]],[1,\"\\n\\n      \"],[1,[28,[35,2],[\"auction.enabled\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"h3\",\"i18n\",\"div\",\"label\",\"input\"]]",
    "moduleName": "discourse/plugins/swapd-auctions/discourse/templates/connectors/category-custom-settings/swapd-auction.hbs",
    "isStrictMode": false
  });
});